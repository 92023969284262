import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Cushion, FlexContainer, Rectangle } from '@pitchero/react-ui';
import ClubLink from '../club-link';
import useBreakpoint from '../../lib/use-breakpoint';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';

const MoreLinkButton = styled.div`
  background-color: white;
  border-radius: 100px;
  border: 2px solid lightgray;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 10px;
  transition: transform 300ms ease-in-out;
  color: black;
  &:hover {
    transform: scale(1.05);
  }
`;

const MoreLink = ({ children, club, route, params, onClick }) => {
  const isMobile = useBreakpoint();
  return (
    <Rectangle style={{ width: '100%' }} component="div">
      <Cushion top={isMobile ? 'small' : 'medium'}>
        <FlexContainer justifyContent="center" component="div">
          {onClick ? (
            <button type="button" onClick={onClick} style={{ width: isMobile ? '100%' : '200px' }}>
              <MoreLinkButton>{children}</MoreLinkButton>
            </button>
          ) : (
            <ClubLink currentClub={club} route={route} params={params}>
              <a style={{ width: isMobile ? '100%' : '200px' }}>
                <MoreLinkButton>{children}</MoreLinkButton>
              </a>
            </ClubLink>
          )}
        </FlexContainer>
      </Cushion>
    </Rectangle>
  );
};

MoreLink.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string,
  params: PropTypes.shape({}),
  club: CLUB_PROP_TYPES,
  onClick: PropTypes.func,
};

MoreLink.defaultProps = {
  params: {},
  onClick: null,
  club: null,
  route: '',
};

export default MoreLink;
