import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ActivityStreamMessage from '../../activity-stream/message';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    content: ' ';
    transition: all 0.25s ease-in-out;
    background-color: ${(props) => (props.transparent ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.5)')};
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
`;

const Item = ({ message, containerRef }) => {
  const ref = useRef(null);
  const [isTransparent, setIsTransparent] = useState(false);

  useEffect(() => {
    const node = ref?.current;
    if (!node || typeof IntersectionObserver !== 'function') {
      return;
    }

    // If more than 50% of the element is visible, make the wrapper transparent
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isTransparent && entry.isIntersecting) {
          setIsTransparent(true);
          observer.disconnect();
        }
      },
      {
        threshold: '0.5',
        root: containerRef.current,
        rootMargin: '0px',
      },
    );

    if (!isTransparent) {
      observer.observe(node);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div ref={ref} className="cardHighlightSlow">
      <Wrapper transparent={isTransparent}>
        <ActivityStreamMessage
          key={message.id}
          message={message}
          stacked="always"
          titleColor="#fff"
          dateColor="#fff"
          isV2
          imageProps={{
            alignment: 'top',
            width: '600',
            height: '336',
            transformation: 'square',
            loading: 'lazy',
          }}
        />
      </Wrapper>
    </div>
  );
};

Item.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
};

export default Item;
